import { cloneDeep } from 'lodash'
import { format } from 'date-fns'

const SUMMARY_BAR_CHART = {
  xAxis: {
    data: [],
    show: false,
  },
  yAxis: { show: false },
  tooltip: {
    trigger: 'axis',
    backgroundColor: '#303136',
    textStyle: {
      color: '#FFFFFF',
    },
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
    formatter: (params) => {
      const { name, data } = params[0]
      return `${name} - ${data} Notes`
    },
  },
  series: [
    {
      type: 'bar',
      data: [],
      itemStyle: {
        borderRadius: [30, 30, 0, 0],
        color: '#DFE9FF',
      },
      emphasis: {
        focus: 'series',
        itemStyle: {
          color: '#4B60F6',
        },
      },
    },
  ],
  grid: { left: '0', right: '0', bottom: '0', height: '45px', containLabel: false },
}

export const getBarChartData = (summariesByDate) => {
  const data = cloneDeep(SUMMARY_BAR_CHART)
  const xAxisLabel = []
  const seriesData = []

  summariesByDate.forEach((item) => {
    // this ensures that the date is formatted as 'MMM d' (e.g. Oct 1) without accounting for TZ 🤮
    const [year, month, day] = item.summary_date.split('-')
    // month is indexed from 0
    xAxisLabel.push(format(new Date(year, Number(month) - 1, day), 'MMM d'))
    seriesData.push(item.total_summaries)
  })

  data.xAxis.data = xAxisLabel
  data.series[0].data = seriesData

  return data
}
