import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Loader, Segment } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import * as redux from '@/reducers/commandCenter/commandCenter.redux'
import * as analyticsActions from '@/reducers/analytics/analytics.actions'
import { ManageFilters } from '@/views/Analytics/components/ManageFilters'

import { AgentCallStatusList } from './components/AgentCallStatusList'
import { Sentiment } from './components/Sentiment'

import './CommandCenter.scss'

export const CommandCenterPage = ({ ldClient }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading: commandCenterLoading } = useSelector((state) => state.commandCenter)
  const [myTeamFetched, setMyTeamFetched] = useState(false)
  const {
    organizationid,
    hierarchy_manager: isHierarchyManager,
    own_organization_id,
  } = useSelector((state) => state.currentUser)
  const childOrgSelected = isHierarchyManager && organizationid !== own_organization_id

  useEffect(() => {
    const flags = ldClient.allFlags()
    // If someone shouldn't have Command Center, we'll give them Call Explorer instead
    if (flags?.showCommandCenter === false) {
      history.push('/call-explorer')
    }
  })

  useEffect(() => {
    async function initialSetup() {
      // Connect to the socket on every page mount
      dispatch(redux.connectCCSocket())
      await dispatch(analyticsActions.fetchSavedFilters('my_team'))
      setMyTeamFetched(true)
    }

    initialSetup()

    return () => {
      // Disconnect from the socket on every page unmount
      dispatch(redux.disconnectCCSocket())
    }
  }, [])

  return (
    <>
      <header className="page-header" data-testid="command-center-page">
        <h1>Command Center</h1>

        <div className="flex medium-gap">
          {/*
          we hide the My Team filters for hierarchy managers impersonating child org.
          we don't support having org-specific filters for one user, or cross-org teams
          */}
          {!childOrgSelected && (
            <ManageFilters
              filterFormOptions={['agents', 'tags']}
              showRemoveFilterButton
              buttonProps={{ secondary: true, basic: false }}
              buttonLabel="My Team"
              noFiltersTitle="Customize Command Center"
              noFiltersMessage="Build your team by selecting individual agents or use tags to create a custom version of Command Center that includes only those agents you need."
              noFiltersButtonLabel="Create Team"
              filterType="my_team"
              hideFilterList
            />
          )}
        </div>
      </header>

      {!myTeamFetched || commandCenterLoading.agents ? (
        <Segment className="not-padded">
          <div className="empty-table">
            <Loader active />
          </div>
        </Segment>
      ) : (
        <>
          <AgentCallStatusList />
          <Sentiment />
        </>
      )}
    </>
  )
}

export default withLDConsumer()(CommandCenterPage)
