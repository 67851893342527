import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'

import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import {
  clearDataMinusOrgs,
  clearFilters,
  clearReportData,
  setData,
} from '@/reducers/analytics/analytics.redux'
import {
  applyFilters,
  deleteSavedFilter,
  setFilterById,
} from '@/reducers/analytics/analytics.actions'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import '@/views/Analytics/AnalyticsPage.scss'
import Select from '@/components/forms/Select'
import { UserInitial } from '@/components/UserInitial'

import { FilterForm } from './FilterForm'
import { SavedFiltersList } from './SavedFiltersList'
import { getTeamFilterAgents } from './SavedFilters.helpers'

export const ManageFilters = ({
  filterFormOptions = [],
  modalProps = {},
  buttonProps = {},
  buttonLabel,
  hideFilterList,
  noFiltersTitle,
  noFiltersMessage,
  noFiltersButtonLabel,
  filterType,
}) => {
  const dispatch = useDispatch()
  const { section } = useParams()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const { organizationid: organziationId } = useSelector((state) => state.currentUser)
  const {
    data: { savedFilters, selectedSavedFilterId },
    loading: { savedFilters: isLoading },
  } = useSelector((state) => state.analytics)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const MY_TEAM = 'my_team'
  const isBaltoAdmin = organziationId === 1

  // Not used for my_team
  const filterOptions = savedFilters.map((filter) => ({
    value: filter.uuid,
    label: filter.name,
  }))

  const isMyTeamFilter = filterType === MY_TEAM

  // Not doing a find so it can return empty array if necessary
  const myTeamFilters = savedFilters.filter((filter) => filter.filter_type === MY_TEAM)
  const allOtherFilters = savedFilters.filter((filter) => filter.filter_type !== MY_TEAM)
  const showNoFiltersMessage =
    (!isMyTeamFilter && isEmpty(allOtherFilters)) || (isMyTeamFilter && isEmpty(myTeamFilters))
  const teamFilterAgents = getTeamFilterAgents(myTeamFilters)

  const openManageFiltersModal = () => {
    dispatch(openModal('analytics/manageFilters'))
  }

  const openCreateFilterModal = () => {
    setSelectedFilter(null)
    dispatch(openModal('analytics/createFilter'))
  }

  const handleCloseModal = () => {
    dispatch(closeModal('analytics/manageFilters'))
  }

  const handleBackButtonClick = () => {
    dispatch(openModal('analytics/manageFilters'))
  }

  const handleEditClick = (event, row) => {
    const selectedFilter = savedFilters.find((filter) => filter.uuid === row.uuid)
    setSelectedFilter(selectedFilter)
    dispatch(openModal('analytics/createFilter'))
  }

  const handleDeleteFilter = (filterId) => {
    dispatch(deleteSavedFilter(filterId, filterType))
  }

  const handleClearFilters = () => {
    dispatch(setData({ selectedSavedFilterId: null }))
    dispatch(clearFilters())
    dispatch(clearReportData())

    if (isBaltoAdmin) {
      dispatch(clearDataMinusOrgs())
    }
  }

  const selectFilterToApply = (option) => {
    if (!option) {
      handleClearFilters()
    } else {
      const filterId = option.value
      dispatch(setFilterById(filterId))
      dispatch(applyFilters(section === 'dynamic-prompt' ? 'deck' : section))
    }
  }

  return (
    <>
      <div data-testid="manage-filters" style={{ display: 'flex', gap: '0.75rem' }}>
        {!isEmpty(teamFilterAgents) && isMyTeamFilter && (
          <Popup
            inverted
            content={
              <div className="text-left">
                {teamFilterAgents.map((filter) => (
                  <div key={filter.label}>{filter.label}</div>
                ))}
              </div>
            }
            trigger={
              <div>
                {teamFilterAgents
                  .filter((filter, index) => index < 3)
                  .map((filter, index) => (
                    <UserInitial
                      userFullName={filter.label}
                      index={index}
                      inline
                      key={filter.label}
                    />
                  ))}
                {teamFilterAgents.length > 3 && (
                  <UserInitial overflowCount={teamFilterAgents.length - 3} inline />
                )}
              </div>
            }
          />
        )}
        <Button
          data-testid="manage-filters-button"
          type="button"
          secondary
          loading={isLoading}
          onClick={openManageFiltersModal}
          {...buttonProps}
        >
          {buttonLabel || 'Manage Filters'}
        </Button>

        {filterOptions.length > 0 && !hideFilterList && (
          <Select
            placeholder="Select Filter"
            options={filterOptions}
            data-testid="filter-select"
            value={selectedSavedFilterId}
            isSearchable={false}
            isClearable
            onChange={selectFilterToApply}
          />
        )}
      </div>

      {modal === 'analytics/manageFilters' && (
        <BasicModal
          data-testid="manage-filters-modal"
          className="manage-filters-modal"
          title={isMyTeamFilter ? 'My Team' : 'Manage My Filters'}
          onClose={handleCloseModal}
          show={!!modal}
          size="small"
          showCloseButton={false}
          footer={
            <Button primary onClick={handleCloseModal}>
              Done
            </Button>
          }
          {...modalProps}
        >
          {showNoFiltersMessage ? (
            <div className="no-filters-wrapper">
              <h1>{noFiltersTitle || 'You have no filters'}</h1>
              <div className="text-center">
                {noFiltersMessage || 'Get started by creating a new filter'}
              </div>
              <Button primary data-testid="create-filter-button" onClick={openCreateFilterModal}>
                {noFiltersButtonLabel || 'Create Filter'}
              </Button>
            </div>
          ) : (
            <>
              {!isMyTeamFilter && !isEmpty(allOtherFilters) && (
                <div className="saved-filters-summary">
                  <span className="saved-filters-count">{allOtherFilters.length} filters</span>
                  <Button data-testid="new-filter-button" secondary onClick={openCreateFilterModal}>
                    New Filter
                  </Button>
                </div>
              )}

              <SavedFiltersList
                savedFilters={isMyTeamFilter ? myTeamFilters : allOtherFilters}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteFilter}
              />
            </>
          )}
        </BasicModal>
      )}

      {modal === 'analytics/createFilter' && (
        <BasicModal
          data-testid="new-filter-modal"
          title={isMyTeamFilter ? 'My Team' : `${selectedFilter ? 'Edit' : 'New'} Filter`}
          onClose={handleCloseModal}
          show={!!modal}
          size="small"
          showCloseButton={false}
        >
          {!isMyTeamFilter && (
            <div className="back-button-wrapper">
              <Button
                icon
                secondary
                type="button"
                className="svg-button"
                onClick={handleBackButtonClick}
              >
                <IconArrowLeft />
                Back
              </Button>
            </div>
          )}
          <FilterForm
            editableFilter={selectedFilter}
            filterFormOptions={filterFormOptions}
            filterType={filterType}
          />
        </BasicModal>
      )}
    </>
  )
}
