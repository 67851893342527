import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { fetchScorecardsByOrg } from '@/reducers/scorecards/scorecards.actions'
import { Button } from 'semantic-ui-react'
import { scoreCopilotScorecard } from '@/reducers/scorecards/scores.actions'

export const ScoreForScorecard = ({
  callId,
  handleDeselectAll,
  organizationId,
  showScoreScorecard = true,
  compact = false,
  buttonProps = {},
  ...props
}) => {
  const [newScoreScorecardModalOpen, setScoreScorecardModalOpen] = useState(false)
  const [selectedScorecardUuid, setSelectedScorecardUuid] = useState(null)
  const { data, loading } = useSelector((state) => state.scorecards)
  const dispatch = useDispatch()

  const scorecardOptions = (data.scorecardConfigs || [])
    .filter((scorecard) => scorecard?.active === true && scorecard?.deleted === false)
    .map((scorecard) => ({
      key: scorecard?.uuid,
      label: scorecard?.name,
      value: scorecard?.uuid,
    }))

  const selectedScorecard = data.scorecardConfigs.find(
    (scorecard) => scorecard.uuid === selectedScorecardUuid
  )

  const handleCloseScoreScorecardModal = async () => {
    setScoreScorecardModalOpen(false)
    setSelectedScorecardUuid(null)
  }

  const handleOpenScoreScorecardModal = async (scorecardUuid) => {
    setSelectedScorecardUuid(scorecardUuid)
    setScoreScorecardModalOpen(true)
  }

  const handleSubmitScorecard = async () => {
    dispatch(scoreCopilotScorecard(organizationId, [callId], selectedScorecard.id))
    await handleCloseScoreScorecardModal()
  }

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchScorecardsByOrg(organizationId, 'copilot'))
    }
  }, [])

  return (
    <>
      <div className="flex-align-center medium-gap">
        {showScoreScorecard && (
          <SearchableDropdown
            buttonProps={{
              content: 'Score Call',
              className: classNames('secondary', compact),
              ...buttonProps,
            }}
            inputProps={{ placeholder: 'Search...' }}
            options={scorecardOptions}
            loading={loading.scorecard}
            menuPosition="right"
            handleSelect={handleOpenScoreScorecardModal}
            dataTestId="playlist"
            {...props}
          />
        )}
      </div>

      {newScoreScorecardModalOpen && (
        <BasicModal
          data-testid="add-to-new-playlist"
          title={`Score Call for ${selectedScorecard?.name}`}
          onClose={handleCloseScoreScorecardModal}
          show={newScoreScorecardModalOpen}
          size="tiny"
        >
          <div>
            <p>
              Please note that this action will bypass audio quality checks, scorecard filters,
              eligibility prompts, and minimum call duration requirements, which can impact the
              accuracy of the scoring. Background noise, poor audio quality, or unclear sound can
              affect the results.
            </p>
            <p>
              If the call has already been scored, the existing scorecard will be deleted. This
              action will also count towards your user quota and may take a few minutes to complete.
            </p>
            <p>Are you sure you want to proceed with scoring this call?</p>
            <div className="modal-buttons">
              <Button
                data-testid="score-scorecard-button"
                icon
                disabled={loading.scorecardConfigs}
                loading={loading.scorecardConfigs}
                primary
                className="svg-button"
                compact={compact}
                onClick={handleSubmitScorecard}
                {...props}
              >
                Submit
              </Button>
            </div>
          </div>
        </BasicModal>
      )}
    </>
  )
}
