import React, { useEffect } from 'react'
import { Button, Form, Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'

import {
  fetchScorecardsByOrg,
  setCurrentOrganizationId,
} from '@/reducers/scorecards/scorecards.actions'
import { loadConfigs } from '@/actions/server'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { clearFilters, setData, setFilter } from '@/reducers/scorecards/scorecards.redux'
import { openModal } from '@/reducers/ui/ui.redux'
import { getOrganizationOptions } from '@/utils/helpers'

import { ScorecardsTable } from './ScorecardsTable'
import { ScorecardWizard } from './forms/AddScorecardForm'

const Scorecards = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { organizations } = useSelector((state) => state)
  const { filters } = useSelector((state) => state.scorecards)
  const currentUser = useSelector((state) => state.currentUser)
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const organizationId = currentUser.organizationid
  const isBaltoAdmin = organizationId === 1
  const organizationOptions = getOrganizationOptions(organizations)
  const isQACopilot = location.pathname.startsWith('/qa-copilot')
  const requestedScorecardType = isQACopilot ? 'copilot' : 'automated'

  const handleFilterChange = (section, value) => {
    dispatch(setFilter(section, value))
  }

  const openScorecardModal = () => {
    dispatch(openModal('newScorecard'))
  }

  const clearOrganization = () => {
    dispatch(clearFilters(true))
    handleFilterChange('organization', '')
    dispatch(setData('scorecardConfigs', []))
    dispatch(setData('currentOrganizationId', ''))
  }

  const handleOrgSelect = (option, action) => {
    if (action.action === 'clear') {
      clearOrganization()
    } else {
      handleFilterChange('organization', option.value)
      dispatch(fetchScorecardsByOrg(option.value, requestedScorecardType))
    }
  }

  useEffect(() => {
    // TODO: Update to not use loadConfigs
    const requestedProperties = 'name,cid,organization_id,organization_name'
    dispatch(loadConfigs({ organizationId, requestedProperties }))

    // Make Balto user select an org to view scorecards
    if (isBaltoAdmin) {
      clearOrganization()
      // Default it to non existent org if not found.
    } else {
      const credentials = currentUser
      const organizationid = credentials ? credentials.organizationid : 0
      dispatch(fetchScorecardsByOrg(organizationId, requestedScorecardType))
      dispatch(setCurrentOrganizationId(organizationid))
    }
  }, [])

  return (
    <div data-testid="scorecards-page">
      <header className="page-header">
        <h1>{isQACopilot ? 'Copilot Scorecards' : 'Scorecards'}</h1>

        {isQACopilot ? (
          <Link
            to="/qa-copilot/create"
            className="ui button primary"
            data-testid="create-qa-copilot-scorecard"
          >
            Create Scorecard
          </Link>
        ) : (
          <Button primary data-testid="add_scorecard" onClick={openScorecardModal}>
            Create Scorecard
          </Button>
        )}
      </header>

      {isBaltoAdmin && (
        <Form className="filter-form">
          <OrganizationFilter
            options={organizationOptions}
            value={filters.organization}
            onChange={handleOrgSelect}
          />
        </Form>
      )}

      {modal === 'newScorecard' && <ScorecardWizard />}

      <Segment className="not-padded">
        <ScorecardsTable isCopilot={isQACopilot} />
      </Segment>
    </div>
  )
}

export default Scorecards
